import linkGroups from "./links.json";

let modals = false;

document.addEventListener("DOMContentLoaded", () => {
    modals = document.querySelector(".graphic__modals") || false;
    if (modals) linkGroups.map(createModal);
    [...document.querySelectorAll(".graphic__scene a")].map((a) =>
        a.classList[
            document.querySelector(
                `[data-modal="${a.getAttribute("href")}"]`
            )
                ? "remove"
                : "add"
        ]("no-modal")
    );
});

const createModal = ({ groupname: name, popup, levels, links }) => {
    const modal = document.createElement("div");

    modal.classList.add("modal");
    modal.classList.add(`modal--${popup.left_or_right}`);
    modal.classList.add(`modal--${popup.top_or_bottom}`);
    modal.setAttribute("id", name);
    modal.setAttribute("data-modal", `#${name}`);

    if (levels && levels.length) modal.classList.add("modal--levels");
    if (links && links.length) modal.classList.add("modal--links");

    if (popup.modifier) {
        modal.setAttribute("data-modal-mod-x", popup?.modifier?.x || 0);
        modal.setAttribute("data-modal-mod-y", popup?.modifier?.y || 0);
    }

    modal.appendChild(createModalLine());
    modal.appendChild(createModalFlare());
    modal.appendChild(createModalClose());

    if (levels && levels.length) modal.appendChild(createLevelsElement(levels));
    if (links && links.length) modal.appendChild(createLinksElement(links));

    modals.appendChild(modal);
    createRenderSpecificStylingForModal(modal);
};

const createLevelsElement = (levels = []) => {
    const levelsEl = document.createElement("div");
    const ul = document.createElement("ul");

    levelsEl.classList.add("modal__levels");

    levels.map(({ name, links }, i) => {
        const levelEl = document.createElement("li");
        const span = document.createElement("span");
        const linksEl = createLinksElement(links);

        levelEl.classList.add("modal__level");
        levelEl.setAttribute("data-behaviour", "hover-levels");
        levelEl.setAttribute("data-for-level", name);
        // if (i === 0) levelEl.classList.add('modal__level--active');

        span.innerText = name;

        linksEl.setAttribute("data-for-level", name);

        levelEl.appendChild(span);

        ul.appendChild(levelEl);

        levelsEl.appendChild(linksEl);
    });
    levelsEl.appendChild(ul);

    return levelsEl;
};

const createLinksElement = (links) => {
    const linksEl = document.createElement("div");
    const ul = document.createElement("ul");

    linksEl.classList.add("modal__links");

    links.map(createLinkElement).map((link) => ul.appendChild(link));

    linksEl.appendChild(ul);

    return linksEl;
};

const createLinkElement = (link) => {
    const listEl = document.createElement("li");
    const anchor = document.createElement("a");

    listEl.classList.add("modal__link");
    anchor.href = link.url;
    anchor.innerText = link.title;
    anchor.setAttribute('target', '_blank');

    listEl.appendChild(anchor);

    return listEl;
};

const createModalLine = () => {
    const line = document.createElement("div");
    const dot = document.createElement("i");

    line.classList.add("modal__line");
    dot.classList.add("modal__line-dot");

    line.appendChild(dot);

    return line;
};

const createModalFlare = () => {
    const flare = document.createElement("i");

    flare.classList.add("modal__flare");

    return flare;
};

const createModalClose = () => {
    const close = document.createElement("button");

    close.classList.add("modal__close");

    return close;
};

const createRenderSpecificStylingForModal = (modal) => {
    const linksEls = [...modal.querySelectorAll(".modal__links ul")];
    if (linksEls.length > 1) {
        linksEls.map((linksEl, i) => {
            linksEl.setAttribute("style", "height: auto;");
            linksEl.classList.add("modal__links--active");

            const linksHeight = linksEl.getBoundingClientRect().height;
            const style = document.createElement("style");

            console.log(modal.id, linksHeight);

            style.setAttribute("id", modal.id);
            style.innerHTML = `.modal#${modal.id}.modal--active .modal__links${
                linksEls.length == 1
                    ? ""
                    : `:nth-child(${i + 1}).modal__links--active`
            } ul {height: ${linksHeight.toFixed(2)}px;}`;

            modal.classList.remove("modal--active");
            linksEl.setAttribute("style", "");
            linksEl.classList.remove("modal__links--active");

            // document.body.appendChild(style);
        });
    } else if (linksEls.length == 1) {
        const linksEl = linksEls[0];

        linksEl.setAttribute("style", "height: auto;");
        linksEl.classList.add("modal__links--active");

        const linksHeight = linksEl.getBoundingClientRect().height;
        const style = document.createElement("style");

        style.setAttribute("id", modal.id);
        style.innerHTML = `.modal#${
            modal.id
        }.modal--active .modal__links ul {height: ${linksHeight.toFixed(
            2
        )}px;}`;

        linksEl.setAttribute("style", "");
        linksEl.classList.remove("modal__links--active");

        document.body.appendChild(style);
    }
};

document.addEventListener("click", (e) => {
    if (!e.target.matches('[data-behaviour="hover-levels"]')) return;

    const forLevel = e.target.dataset.forLevel;
    const modal = e.target.closest(".modal");
    const links = modal.querySelector(
        `.modal__links[data-for-level="${forLevel}"]`
    );

    [...modal.querySelectorAll(".modal__links--active")].map((link) =>
        link.classList.remove("modal__links--active")
    );

    modal.classList.add('modal--levels-active');
    links.classList.add("modal__links--active");
});
