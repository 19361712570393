import panzoom from "@panzoom/panzoom";

(() => {
    const graphicTarget = '[data-behaviour="panzoom"]';
    const graphic = document.querySelector(graphicTarget);

    if (!graphic) return;

    const scene = panzoom(graphic, {
        minScale: 1,
        maxScale: 2.5,
        animate: true,
        relative: false,
        isSvg: true,
        step: 1.5,
        canvas: false,
        cursor: 'grab',
        duration: 300
    });

    const openLink = (url, newTab = false) => {
        const a = document.createElement("a");

        a.setAttribute("href", url);

        if (newTab) a.setAttribute("target", "_blank");

        a.click();
        a.remove();
    }

    const openModal = (link, targetRect, scale) => {
        const modal = document.querySelector(`[data-modal="${link}"]`);
        let top = '50%';
        let left = '50%';
        let right = '50%';

        const modifiers = {
            x: modal.dataset.modalModX || 0,
            y: modal.dataset.modalModY || 0,
        }

        switch(true) {
            case modal.matches('.modal--top'):
                top = `calc(50% - ${(targetRect.height / 5)}px + ${16}px  - ${modifiers.y}px)`;
                break;
            case modal.matches('.modal--bottom'):
                top = `calc(50% + ${(targetRect.height / 5)}px - ${16}px - ${modifiers.y}px)`;
                break;
        }
        modal.classList.add('modal--active');
        
        switch(true) {
            case modal.matches('.modal--left'):
                right = `calc(50% + ${(targetRect.width / 5)}px - ${modifiers.x}px)`;
                modal.setAttribute('style', `
                right: ${right};
                top: ${top};
            `);
                break;
            case modal.matches('.modal--right'):
                left = `calc(50% + ${(targetRect.width / 5)}px - ${modifiers.x}px)`;
                    modal.setAttribute('style', `
                    left: ${left};
                    top: ${top};
                `);
                break;
        }
    };

    const closeModals = () => [...document.querySelectorAll('.modal--active')].map(el => el.classList.remove('modal--active') && el);
    const closeLinks = () => [...document.querySelectorAll('.modal__links--active')].map(el => el.classList.remove('modal__links--active'));
    const closeLevels = () => [...document.querySelectorAll('.modal--levels-active')].map(el => el.classList.remove('modal--levels-active'));
    const resetModals = () => {
        closeModals();
        closeLinks();
        closeLevels();
        
        return true;
    }

    document.addEventListener(
        "click",
        (e) => {
            if (!e.target.matches('[data-behaviour="panzoom-zoom"]')) return;

            resetModals();
            const dir = e.target.matches('[data-zoom-direction="in"]')
                ? "zoomIn"
                : "zoomOut";
            scene[dir]({ animate: true });
            if (dir === "zoomOut") scene.pan('50%', '50%', { animate: true });
        }
    );

    document.addEventListener("click", (e) => {
        if (
            !e.target.matches(`${graphicTarget} a`) &&
            !e.target.closest(`${graphicTarget} a`)
        )
            return;

        e.preventDefault();

        const target =
            e.target.matches(`${graphicTarget} a`) ||
            e.target.closest(`${graphicTarget} a`);
        
        const targetRect = target.getBoundingClientRect();

        const pan = scene.getPan();
        const zoom = scene.getScale();
        const screenCenter = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
        };

        const point = {x: 0, y: 0};

        point.x = (pan.x + ((screenCenter.x - targetRect.width / 2) - targetRect.x) / zoom);
        point.y = (pan.y + ((screenCenter.y - targetRect.height / 2) - targetRect.y) / zoom);
        
        scene.pan(point.x, point.y, { animate: true });
        scene.zoom(2.5);
        
        resetModals();
        
        setTimeout(() => openModal(target.getAttribute('href'), target.getBoundingClientRect(), scene.getScale()), 300);
    });

    document.addEventListener('click', e => e.target.matches('.modal__close') && resetModals());

    graphic.addEventListener('pointerdown', () => {
        scene.setOptions({ cursor: 'grabbing' });
        resetModals();
    });
    graphic.addEventListener('pointerup', () => scene.setOptions({ cursor: 'grab' }));
    window.addEventListener('resize', resetModals);

    graphic.addEventListener('panzoomzoom', () => {
        document.querySelector('.graphic').classList[scene.getScale() > 1 ? 'add' : 'remove']('graphic--zoomed');
    });
})(window);
